import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { ButtonGradientNavbar, ButtonRadial } from '../../elements/Buttons';
import ReturnArrow from '../../icons/ReturnArrow.svg';
import YoStoryNavbar from '../../icons/YoStoryNavbar.svg';

function HeaderNavbar(props) {
  return (
    <Navbar expand="lg" className="bg-body-light justify-content-between" style={{paddingBottom: '1em', backgroundColor: '#ffffff'}}>
      <Container>
        {/* <Navbar.Brand as={Link} to="/">YoStory</Navbar.Brand> */}
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <ButtonRadial as={Link} to=".." relative="path">
          <img src={ReturnArrow} alt='Return'></img>
        </ButtonRadial>
        <ButtonGradientNavbar as={Link} to="/">
          <img src={YoStoryNavbar} alt="YoStory"></img>
        </ButtonGradientNavbar>
        {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/account">Мой аккаунт</Nav.Link>
            <Nav.Link as={Link} to="/subscription">Оформить подписку</Nav.Link>
            <Nav.Link as={Link} to="/referral">Реферальная программа</Nav.Link>
            <Nav.Link as={Link} to="/information">Информация</Nav.Link>
            <Nav.Link as={Link} to="/support" disabled>Канал YoStory</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default HeaderNavbar;