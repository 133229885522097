import React from "react";
import styled from "styled-components";
import YoStoryMainLogo from "../../icons/YoStoryMainLogo.png"
// import YoStoryMainLogo from "../../icons/YoStoryAltLogo.svg"

const StyledLabel = styled.div`
  height: 6em;
  width: 297px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;

  & .yo-story {
    color: transparent;
    font-family: "Kodchasan-SemiBold", Helvetica;
    font-size: 5em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
  }

  & .text-wrapper {
    color: #6b08fb;
  }

  & .span {
    color: #3e3e3e;
  }
`;

export const LabelYoStory = () => {
  return (
    <StyledLabel>
      <img src={YoStoryMainLogo} alt="YoStory" />
      {/* <p className="yo-story">
        <span className="text-wrapper">Yo</span>
        <span className="span">Story</span>
      </p> */}
    </StyledLabel>
  );
};