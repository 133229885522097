import * as React from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom';

import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';
import { ButtonCopy, ButtonSubscriptionChoice, ButtonOutlineSubscriptionChoice } from '../Buttons';


const ReferralCardContainer = styled(Card)`
  color: #ffffff;
  border-radius: 1em;
  padding: 0.5em;
  border: 0px;
  background: linear-gradient(
    211.56deg,
    rgba(70, 196, 232, 1) 0.4416228737682104%,
    rgba(203, 75, 227, 1) 100%
  );
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
`
const SubscriptionOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const SubscriptionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
`

const SubscriptionDescriptionCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
`

const ReferralInfoCategory = styled.div`
  font-size: 1em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`

const SubscriptionNumber = styled.div`
  font-size: 1em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`
const SubscriptionPrice = styled.div`
  display: flex;
  flex-direction: column;
`

const SubscriptionPriceFocus = styled.div`
  color: #9D75E5;
`

const SubscriptionPriceOutOfFocus = styled.div`
  color: #BDBBBB;
  font-size: 0.8em;
  text-decoration: line-through;
`
const formatDateToDDMMYYYY = ( date ) => {
  if (date) {
    const day = String(date.getDate()).padStart(2, '0'); // Gets the day and pads with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Gets the month (0-based) and pads with zero
    const year = date.getFullYear(); // Gets the full year
    return `${day}.${month}.${year}`; // Formats as DD.MM.YYYY
  } else {
    return '-'
  }
}

export const ReferralCard = ({ referralPlan, subscriptionPlan, handleCopyToClipboard }) => {

    return (
      <ReferralCardContainer>
        <Card.Body>  
          {/* <TopHeader>
            Баланс
          </TopHeader> */}
          <Card.Text>
            <div>
              <SubscriptionDescription>
                <ReferralInfoCategory>Бонусы</ReferralInfoCategory>
                <SubscriptionNumber>{subscriptionPlan.bonus_points}</SubscriptionNumber>
              </SubscriptionDescription>
              {/* <SubscriptionDescription>
                <ReferralInfoCategory>Срок подписки</ReferralInfoCategory>
                <SubscriptionNumber>1 месяц</SubscriptionNumber>
              </SubscriptionDescription> */}
              <SubscriptionDescription>
                <ReferralInfoCategory>Всего продаж</ReferralInfoCategory>
                <SubscriptionNumber>{referralPlan.referral_counter}</SubscriptionNumber>
              </SubscriptionDescription>
              <SubscriptionDescription>
                <ReferralInfoCategory>Сумма продаж</ReferralInfoCategory>
                <SubscriptionNumber>{referralPlan.bonus_points}</SubscriptionNumber>
              </SubscriptionDescription>
              {/* <SubscriptionDescriptionCentered>
                <SubscriptionPrice>
                    <center>
                    <ReferralInfoCategory>Реферальный код</ReferralInfoCategory>
                    <ReferralInfoCategory style={{ 
                        fontSize: '0.8em',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: 'inline-block',
                    }}>{btoa(referralPlan.referral_code)}</ReferralInfoCategory>
                    </center>
                </SubscriptionPrice>
              </SubscriptionDescriptionCentered> */}
            </div>
          </Card.Text>
          <Stack gap={2}>
            <ButtonSubscriptionChoice as={Link} to="/referral/support"  variant="primary">
              <SubscriptionOptions>
                <div>Вывод денег</div>
              </SubscriptionOptions>
            </ButtonSubscriptionChoice>
          </Stack>
        </Card.Body>
      </ReferralCardContainer>
    );
  };