import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import Home from './components/Home';
import Referral from './components/Referral';
import Account from './components/Account';
import AccountStyled from './components/AccountStyled';
import Support from './components/Support';
import Subscription from './components/Subscription';
import PurchaseBonus from './components/PurchaseBonus';
import Information from './components/Information';
import PrivacyPolicy from './components/PrivacyPolicy';
import UserAgreement from './components/UserAgreement';
import LegalNotice from './components/LegalNotice';
import ParticlesBg from 'particles-bg';
import { WebAppProvider, MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';
import { useExpand } from "@vkruglikov/react-telegram-web-app";

import FormDownloadResult from './components/FormDownloadResult';
import HeaderNavbar from './components/HeaderNavbar';
import { HashRouter, BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isExpanded, expand] = useExpand(true);
  const [userData, setUserData] = useState(null);

  const handleExpand = () => !isExpanded && expand();
  const tg = window.Telegram.WebApp;

  tg.expand();
  tg.disableVerticalSwipes();

  useEffect(() => {
    // Ensure that the Telegram WebApp is defined
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      // const search = tg.initData
      // const converted = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
      // const user = JSON.parse(converted.user);
      // Access the user data directly from the Telegram object
      const user = tg.initDataUnsafe?.user;

      if (user) {
        // Extract user details
        const userInfo = {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          username: user.username,
          photoUrl: user.photo_url,
          languageCode: user.language_code,
        };

        setUserData(userInfo);
      } else {
        console.error("User data is not available.");
      }
    } else {
      console.error("Telegram WebApp is not available.");
    }
  }, []);

  useEffect(() => {
    // alert(tg.version)
    tg.expand();
    tg.disableVerticalSwipes();
    tg.onEvent('viewportChanged', () => {
      tg.expand();
      tg.disableVerticalSwipes();
    });
    // alert(tg.isVerticalSwipesEnabled)
  });

  return (
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
        isExpanded: true,
      }}
    >
      <BrowserRouter>
        <div className='app-main-container scrollable-element'>
          {/* <ImageUpload className='app-form-container'/> */}
          <Routes>
            <Route path="/" element={<Home userData={userData} />} />
            <Route
              element={(
                <>
                  <HeaderNavbar />
                  <Outlet />
                </>
              )}
              >
              <Route path="/account" element={<Account userData={userData}/>} />
              <Route path="/referral" element={<Referral userData={userData}/>} />
              <Route path="/subscription" element={<Subscription userData={userData}/>} />
              <Route path="/purchaseBonus" element={<PurchaseBonus userData={userData}/>} />
              <Route path="/information" element={<LegalNotice />} />
              <Route path="/information/user-agreement" element={<UserAgreement />} />
              <Route path="/information/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/referral/support" element={<Support userData={userData}/>} />
            </Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
            <Route path='/user-agreement' element={<UserAgreement />} />
          </Routes>
        </div>
        {/* <div className='app-main-container'>
          <FormDownloadResult className='app-form-container'/>
        </div> */}
        {/* <ParticlesBg type="circle" bg={true} /> */}
        {/* <MainButton onClick={handleExpand} text={isExpanded ? "Свернуть" : "Развернуть"}>{isExpanded ? "Свернуть" : "Развернуть"}</MainButton>; */}
      </BrowserRouter>
      {/* <MainButton /> */}
      {/* <BackButton /> */}
    </WebAppProvider>
  );
}

export default App;
