import React from "react";
import styled from "styled-components";
import Ripples from 'react-ripples';

import Button from 'react-bootstrap/Button';

const ButtonCopy = styled(Button)`
  position: absolute;
  top: 10px;   // Adjust for your desired positioning
  right: 10px; // Adjust for your desired positioning
  background: transparent;
  border: none;
  color: #ffffff; // Bootstrap primary color
  cursor: pointer;

  &:hover {
    color: yellow; // Darker shade on hover
  }
`;

export const ButtonGradient = styled(Button)`
  cursor: pointer;
  width: 80%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover {
    background: yellow;
    color: #000000;
  };

  &:active {
    background: rgb(203, 75, 227) !important;
    color: #ffffff;
  };
  `;


  export const ButtonSmallGradient = styled(Button)`
  cursor: pointer;
  
  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover {
    background-color: yellow;
    color: #000000;
  };

  &:active {
    background-color: rgb(203, 75, 227) !important;
    background: rgb(203, 75, 227) !important;
    color: #ffffff;
  };

  &:disabled {
    background: rgb(211, 211, 211) !important;
    color: #ffffff;
  }
  `;

// export function ButtonGradient(props) {
//   return (
//     <Ripples>
//       <ButtonGradientElement {...props}/>
//     </Ripples>
//   );
// };

export const ButtonGradientNavbar = styled(Button)`
  cursor: pointer;
  width: 6em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;
  `;

export const ButtonRadial= styled(Button)`
  cursor: pointer;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 50%;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;
`;

export const ButtonSubscriptionChoice = styled(Button)`
  cursor: pointer;
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  
  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 1em;
  padding: 0.5em;
  color: #000000;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover:not([disabled]) {
    background-color: yellow;
    color: #000000;
  };

  &:active {
    background-color: rgb(203, 75, 227) !important;
    background: rgb(203, 75, 227) !important;
  };

  &:disabled {
    cursor: not-allowed !important;
    background-color: #ffffff !important;
    background: #ffffff !important;
    color: #000000;
  }
  `;


export const ButtonOutlineSubscriptionChoice = styled(Button)`
  cursor: pointer;
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0,0,0,0);
  border: 0.15em solid rgb(255, 255, 255);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffffs;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: yellow;
    color: #000000;
    border: none !important;sss
  };

  &:active {
    background-color: rgb(203, 75, 227) !important;
    background: rgb(203, 75, 227) !important;
    border: none !important;
  };

  &:disabled {
    background: rgb(211, 211, 211) !important;
  }
  `;