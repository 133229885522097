import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Toast, ToastContainer } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { SubscriptionCard, dummySubscriptionPlans, dummySubscriptionPlansBlocked } from '../../elements/SubscriptionCard';
import { ButtonGradient, ButtonSmallGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

import { handlePurchaseAction } from '../../actions/payment';
import { crudGetSubscriptionPlanByTelegramId, crudGetReferralPlanByReferralCode } from '../../actions/interactions';

const PaymentLink = styled.a`
  color: #ffffff;
  font-family: "MontserratAlternates";
  font-weight: bold;
  outline: none;
  text-decoration: none;
  -moz-outline-style: none; 

  :active, :focus { outline: none; -moz-outline-style: none; }

`

const Subscription = ({userData}) => {

  const [show, setShow] = useState(false);
  const [modalLink, setModalLink] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [modalName, setModalName] = useState();
  const [modalDuration, setModalDuration] = useState();
  const [email, setEmail] = useState();
  const [referralCode, setReferralCode] = useState();
  const [referralCodeToUse, setReferralCodeToUse] = useState();
  const [referralBonusTypeToUse, setReferralBonusTypeToUse] = useState(0);

  const [subscriptionPlanData, setSubscriptionPlanData] = useState({});
  const [referralPlanData, setReferralPlanData] = useState({});

  const [showToast, setShowToast] = useState(false); // State to manage toast visibility
  
  const userTelegramId = userData.id
  const userUsername = userData.username
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOpenPageByModalLink = () => window.open(modalLink, "_blank");
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  const handleReferralCodeChange = (event) => {
    const newReferralCode = event.target.value;
    setReferralCode(newReferralCode);
  };
  const handlePreventEnter = (event) => {
    if (['Enter', '13', 13].includes(event.target.key)) {
      event.preventDefault();
    }
  };
  const handlePreventDefault = (event) => {
    event.preventDefault();
    if (document.activeElement) {
      document.activeElement.blur();
    };
  };

  const handleSubmitReferralCode = (event) => {
    event.preventDefault();
    if (referralCode) {
      setReferralCodeToUse(referralCode)
    } else {
      alert(`Реферальный код ${referralCode} не может быть принят!`)
        // Clipboard API is not supported
    }
  };

  useEffect(() => {
    crudGetSubscriptionPlanByTelegramId(userTelegramId).then(crudSubscriptionPlanData => {
      if (crudSubscriptionPlanData && crudSubscriptionPlanData.email && !email) {
        setEmail(crudSubscriptionPlanData.email);
      };
      setSubscriptionPlanData(crudSubscriptionPlanData);
    });
  }, []);

  return (
    <>
    <ToastContainer position="top-end" className="p-3">
      <Toast show={showToast} onClose={() => setShowToast(false)}>
          <Toast.Header>
              <strong className="me-auto">Уведомление</strong>
              <small>Только что</small>
          </Toast.Header>
          <Toast.Body>Реферальный код {referralCodeToUse} принят!</Toast.Body>
      </Toast>
    </ToastContainer>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <ParagraphText>
            Оплата заказа
          </ParagraphText></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParagraphText>Вы хотите оформить подписку</ParagraphText>
        <ParagraphText><strong>«{modalName}»</strong> на <strong>{modalDuration}</strong></ParagraphText>
        <PaymentLink href={modalLink}><ButtonGradient variant="primary">Перейти на страницу оплаты</ButtonGradient></PaymentLink></Modal.Body>
      </Modal>
    <Stack gap={2} className="col-md-5 mx-auto">
      <center>
        <TopHeader>Оформить подписку</TopHeader>
      </center>
      <div>
        <Form onSubmit={handlePreventDefault}>
          <Form.Group className="mb-3" controlId="email-form.ControlInput1">
            <Form.Label>Введите ваш email</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" required autocomplete="on" value={email} onChange={handleEmailChange} />
            <Form.Control.Feedback type="invalid">
              Ввод почтового адреса обязателен для оформления подписки
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        {subscriptionPlanData && !subscriptionPlanData.referral_code_used ? <Form onSubmit={handleSubmitReferralCode}>
          <Row className="align-items-center">
            <Col xs="auto">
              <Form.Label htmlFor="form-input-referral-code" >
                Введите реферальный код или промо-код
              </Form.Label>
              <Form.Control
                className="mb-2"
                id="form-input-referral-code"
                placeholder="Нет кода"
                disabled={referralCodeToUse}
                onChange={handleReferralCodeChange}
              />
            </Col>
            <Col xs="auto">
              <ButtonSmallGradient type="submit" disabled={!referralCode || referralCodeToUse} className="mb-2">
                Принять
              </ButtonSmallGradient>
            </Col>
          </Row>
        </Form> 
        : null }
      </div>
      {dummySubscriptionPlans.map((subscriptionPlan) => (
        <SubscriptionCard subscriptionPlan={subscriptionPlan} userData={userData} email={email} referralCode={referralCodeToUse} handlePurchaseAction={handlePurchaseAction} handleShow={handleShow} isInProgress={isInProgress} setIsInProgress={setIsInProgress} setModalLink={setModalLink} setModalName={setModalName} setModalDuration={setModalDuration}/>
      ))}
    </Stack>
    </>
  );
}

export default Subscription;