// import { YooKassa, CurrencyEnum, ConnectorOpts } from 'yookassa-sdk';
import { YooCheckout } from '@a2seven/yoo-checkout'; // OR const { YooCheckout } = require('@a2seven/yoo-checkout');
import axios from 'axios';
// import crypto from 'crypto';
import uuid from 'react-uuid'
import { crudGetReferralPlanByReferralCode } from '../actions/interactions';

const initOpts = {
    // debug: true,
    secretKey: `${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`,
    shopId: `${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`,
};
const idempotenceKey = '02347fc4-a1f0-49db-807e-f0d62c2ed5a5';
const sdk = new YooCheckout({ shopId: `${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`, secretKey: `${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`});
const urlPayment = `${process.env.REACT_APP_API_PAYMENT}/api/v1/payment/yookassa/createPayment`;

// async function createPayment(data) {
//     try {
//         const response = await sdk.createPayment(data, idempotenceKey).catch(console);
//         return response;
//       } catch (error) {
//         console.log(error)
//         return null;
//       }
// }

export const handlePurchaseAction = async (subscriptionPlan, userData, email, referralCode, option, setModalLink) => {
    // console.log(`${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`,`${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`,)
    const referralPlanData = await crudGetReferralPlanByReferralCode(referralCode)
        .then(function (result) { 
            return result
        })
        .catch(function (error) {
            console.log(error);
            return {}
        });
    const price = option.priceFocus
    const renewalPrice = option.priceFocus
    const numberOfTexts = subscriptionPlan.numberOfTexts
    const duration = option.duration
    const renewalsPerformed = 0
    const uuidIdempotenceKey = uuid()

    const subscriptionPlanTier = subscriptionPlan.subscriptionPlanTier
    const article = "subscription_plan"
    const username = userData.username
    const userId = userData.id
    const referralCodeDecoded = referralCode ? atob(referralCode) : null
    const referralCodeValue = referralCodeDecoded ? 5.0 : 0.0
    const referralBonusType = referralPlanData.referral_bonus_type
    const priceAdjusted = Math.floor(price * (1 - (referralCodeValue * 0.01)))
    const bonusPoints = price - priceAdjusted
    const userEmail = email 
    const name = subscriptionPlan.name
    const data = {
        "amount": {
            "value": priceAdjusted,
            "currency": "RUB"
        },
        "confirmation": {
            "type": "redirect",
            "return_url": "https://t.me/yostory_bot"
        },
        "capture": true,
        "description": "Заказ подписки",
        "metadata": {
            "article": article,
            "username": username,
            "user_id": userId,
            "subscription_plan_tier": subscriptionPlanTier,
            "number_of_texts": numberOfTexts,
            "email": userEmail,
            "referral_code": referralCodeDecoded,
            "referral_code_value": referralCodeValue,
            "referral_bonus_type": referralBonusType,
            "renewal_price": renewalPrice,
            "bonus_points": bonusPoints,
            "renewal_period_days": duration,
            "renewals_performed": renewalsPerformed,
            "duration": duration,
        },
        "receipt": {
            "customer": {
                "email": userEmail,
            },
            "items": [
                {
                    "description": name,
                    "quantity": "1.00",
                    "measure": "piece",
                    "amount": {
                        "value": priceAdjusted,
                        "currency": 'RUB'
                    },
                    "tax_system_code": "2",
                    "vat_code": "1",
                    "payment_mode": "full_payment",
                    "payment_subject": "service",
                    // "country_of_origin_code": "RU",
                },
            ],
            "settlements": [
                {
                    "type": "cashless",
                    "amount": {
                        "value": priceAdjusted,
                        "currency": 'RUB'
                    },
                }
            ]
        }
    }
    // const paymentResult = createPayment(data);
    const urlConfirmation = await axios.post(urlPayment, {'data': data}, {
        headers: {
            // 'Idempotence-Key': uuidIdempotenceKey,
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    .then(function (response) {
    //   console.log(response);
      const urlConfirmation = response.data.result.confirmation.confirmation_url;
    //   console.log(urlConfirmation);
      setModalLink(urlConfirmation);
      return urlConfirmation
    })
    .catch(function (error) {
      console.log(error);
      setModalLink(null);
      return null
    });
    // console.log('Payment created: ', paymentResult);
    return urlConfirmation 
}


export const handlePurchaseBonusAction = async (userData, email, option, setModalLink) => {
    // console.log(`${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`,`${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`,)
    const price = option.priceFocus
    const bonusPoints = option.bonusNumber
    const renewalsPerformed = 0
    const uuidIdempotenceKey = uuid()

    const article = "subscription_bonus_points"
    const username = userData.username
    const userId = userData.id
    const userEmail = email 
    const name = option.name
    const data = {
        "amount": {
            "value": price,
            "currency": "RUB"
        },
        "confirmation": {
            "type": "redirect",
            "return_url": "https://t.me/yostory_bot"
        },
        "capture": true,
        "description": "Заказ бонусных сказок",
        "metadata": {
            "article": article,
            "username": username,
            "user_id": userId,
            "email": userEmail,
            "bonus_points": bonusPoints,
        },
        "receipt": {
            "customer": {
                "email": userEmail,
            },
            "items": [
                {
                    "description": name,
                    "quantity": "1.00",
                    "measure": "piece",
                    "amount": {
                        "value": price,
                        "currency": 'RUB'
                    },
                    "tax_system_code": "2",
                    "vat_code": "1",
                    "payment_mode": "full_payment",
                    "payment_subject": "service",
                    // "country_of_origin_code": "RU",
                },
            ],
            "settlements": [
                {
                    "type": "cashless",
                    "amount": {
                        "value": price,
                        "currency": 'RUB'
                    },
                }
            ]
        }
    }
    // const paymentResult = createPayment(data);
    const urlConfirmation = await axios.post(urlPayment, {'data': data}, {
        headers: {
            // 'Idempotence-Key': uuidIdempotenceKey,
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    .then(function (response) {
    //   console.log(response);
      const urlConfirmation = response.data.result.confirmation.confirmation_url;
    //   console.log(urlCo/nfirmation);
      setModalLink(urlConfirmation);
      return urlConfirmation
    })
    .catch(function (error) {
      console.log(error);
      setModalLink(null);
      return null
    });
    // console.log('Payment created: ', paymentResult);
    return urlConfirmation 
}
